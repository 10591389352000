import { Category } from "../../domain/entities/Category"

interface CategoryChildren {
	id?: string
	label: string
	position: string
	picture: string
	parent_id: string
	highlighted?: boolean
	country_id?: string
	children?: CategoryChildren[]
}
interface AssociatedService {
	id: number
	title: string
	sellerName: string
	weight: number
}

interface ContructorParams {
	id?: string
	label?: string
	labelEn?: string
	labelCa?: string
	position?: string
	picture?: string
	children?: CategoryChildren[]
	parentId?: string
	weight?: string
	highlighted?: boolean
	countryId?: string
	iconId?: number
	iconKey?: string
	iconPicture?: string
	micrositesAssociated?: { id: number; slug: string }[]
	servicesAssociated?: AssociatedService[]
}

export class CategoryModel {
	public id?: string
	public label?: string
	public labelEn?: string
	public labelCa?: string
	public position?: string
	public picture?: string
	public children?: CategoryChildren[]
	public parentId?: string
	public weight?: string
	public highlighted?: boolean
	public countryId?: string
	public iconId?: number
	public iconKey?: string
	public iconPicture?: string
	public micrositesAssociated?: { id: number; slug: string }[]
	public servicesAssociated?: AssociatedService[]

	constructor(params: ContructorParams) {
		this.id = params.id
		this.label = params.label
		this.labelEn = params.labelEn
		this.labelCa = params.labelCa
		this.position = params.position
		this.picture = params.picture
		this.children = params.children
		this.parentId = params.parentId
		this.weight = params.weight
		this.highlighted = params.highlighted
		this.countryId = params.countryId
		this.iconId = params.iconId
		this.iconKey = params.iconKey
		this.iconPicture = params.iconPicture
		this.micrositesAssociated = params.micrositesAssociated
		this.servicesAssociated = params.servicesAssociated
	}

	static fromRawJson = (rawJson: any): CategoryModel => {
		const {
			id,
			clusterId,
			clusterName,
			label,
			labelEn,
			labelCa,
			position,
			picture,
			children,
			weight,
			parentId,
			countryId,
			highlighted,
			iconId,
			iconKey,
			iconPicture,
			micrositesAssociated,
			servicesAssociated
		} = rawJson

		return new CategoryModel({
			id: id ?? clusterId,
			label: label ?? clusterName,
			labelEn,
			labelCa,
			position,
			picture,
			children,
			weight,
			parentId,
			countryId,
			iconId,
			iconKey,
			iconPicture,
			highlighted,
			micrositesAssociated,
			servicesAssociated
		})
	}

	static fromDomain = (category: Category): CategoryModel => {
		const {
			id,
			label,
			labelEn,
			labelCa,
			children,
			parentId,
			position,
			weight,
			countryId,
			iconId,
			highlighted,
			servicesAssociated
		} = category

		return new CategoryModel({
			id,
			label,
			labelEn,
			labelCa,
			parentId,
			position,
			picture: label, // Actually "picture" database field is beeing used to store the category label/title
			children: children?.map(child => ({
				...child,
				parent_id: child.parentId,
				country_id: child.countryId
			})),
			weight,
			countryId,
			iconId,
			highlighted,
			servicesAssociated
		})
	}
}

declare module "./CategoryModel" {
	interface CategoryModel {
		toDomain(): Category
	}
}

CategoryModel.prototype.toDomain = function (): Category {
	const data = this
	return new Category({
		...data,
		parentId: data.parentId,
		countryId: data.countryId,
		labelEn: data.labelEn,
		labelCa: data.labelCa,
		children: data.children?.map(child => ({
			...child,
			countryId: child.country_id,
			parentId: child.parent_id,
			children:
				child.children?.map(childChild => ({
					...childChild,
					parentId: childChild.parent_id
				})) || []
		}))
	})
}
