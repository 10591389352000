import { Button, Col, Form, Layout, Row, Typography } from "antd"
import signinbg from "../../assets/images/img-signin.jpg"
import logo from "../../assets/images/Imagotipo sporttips.png"
import { observer } from "mobx-react"
import { useEffect, useState } from "react"
import { Login } from "../../../domain/entities/login"
import { ForgotViewModel } from "./ForgotViewModel"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useToastMessage } from "../../service/toastMessage"
import { FormField } from "../../components/Form/FormField"
import { homePageRoute } from "../../navigation/routes"
import { InputType } from "../../components/TextInput/const/InputType.enum"
import { AuthStore } from "../../stores/AuthStore"
import { AxiosError } from "axios"

const { Title } = Typography
const { Header, Content } = Layout

const initialEmailFormData = {
	email: ""
}

const initialCodeValidationFormData = {
	validationCode: "",
	password: ""
}

const ForgotView = () => {
	const navigate = useNavigate()
	const viewModel = useContainerInjection<ForgotViewModel>("ForgotViewModel")
	const { isAuthenticated } = useContainerInjection<AuthStore>("AuthStore")
	const [emailFormData, setEmailFormData] = useState<{ email: string }>(initialEmailFormData)
	const [validationFormData, setValidationFormData] = useState<any>(initialCodeValidationFormData)
	const { t } = useTranslation("forgotView")
	const { context, show } = useToastMessage()
	const [searchParams, setSearchParams] = useSearchParams()

	useEffect(() => {
		if (isAuthenticated) {
			navigate(homePageRoute, { replace: true })
		} else {
			const emailFromQuery = searchParams.get("email")
			if (emailFromQuery) {
				setEmailFormData({ ...emailFormData, email: emailFromQuery })
				viewModel.setCodeSent(true)
			}

			const newSearchParams = new URLSearchParams(searchParams)
			newSearchParams.delete("email")

			navigate({ search: newSearchParams.toString() }, { replace: true })
		}
	}, [searchParams, navigate, isAuthenticated])

	const submitPasswordRecovery = async () => {
		try {
			if (emailFormData.email) {
				await viewModel.triggerPasswordRecovery(emailFormData.email)
			}
		} catch (error) {
			show({
				type: "error",
				content: "Something failed, try again"
			})
		}
	}

	const submitLogin = async () => {
		try {
			const loginWithCodeForm: Login = {
				email: emailFormData.email,
				password: validationFormData.password,
				passwordCode: validationFormData.validationCode
			}
			await viewModel.login(loginWithCodeForm)
			navigate(homePageRoute, { replace: true })
		} catch (error: any) {
			let content = t("genericError")
			if (error && typeof error.httpStatus === "number" && error.httpStatus === 401) {
				content = t("invalidValidationCode")
			}
			show({
				type: "error",
				content
			})
		}
	}

	const emailForm = (
		<Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 2 }} md={{ span: 12 }}>
			<Title className="mb-15 astoria-medium">{t("title")}</Title>
			<Title className="font-regular text-muted" level={5}>
				{t("description")}
			</Title>
			<Form onFinish={submitPasswordRecovery} initialValues={initialEmailFormData}>
				<FormField.Input
					key={"email"}
					label={t("email")}
					name={"email"}
					inputType={InputType.EMAIL}
					mandatory
					disabled={viewModel.isLoading}
					onChange={(value: string) => {
						setEmailFormData({ ...emailFormData, email: value })
					}}
				/>

				<Form.Item>
					<Button
						htmlType="submit"
						disabled={viewModel.isLoading}
						className="w-full gap-2 flex items-center justify-center"
						loading={viewModel.isLoading}
					>
						{t("continueBtn")}
					</Button>
				</Form.Item>
			</Form>
		</Col>
	)

	const codeValidationForm = (
		<Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 2 }} md={{ span: 12 }}>
			<Title className="mb-15 astoria-medium">{t("validateEmailTitle")}</Title>
			<Title className="font-regular text-muted" level={5}>
				{t("validationCodeSent").replace("{email}", emailFormData.email)}
			</Title>
			<Form onFinish={submitLogin} initialValues={initialCodeValidationFormData}>
				<FormField.Input
					key={"validationCode"}
					label={t("validationCode")}
					name={"validationCode"}
					inputType={InputType.NUMBER}
					autoComplete={"off"}
					mandatory
					onChange={(value: string) => {
						setValidationFormData({ ...validationFormData, validationCode: value })
					}}
				/>
				<FormField.Input
					key={"password"}
					label={t("password")}
					name={"password"}
					inputType={InputType.PASSWORD}
					autoComplete={"off"}
					rules={[
						{
							required: true,
							message: t("inputPassword")
						}
					]}
					onChange={(value: string) => {
						setValidationFormData({ ...validationFormData, password: value })
					}}
				/>
				<FormField.Input
					key={"confirmPassword"}
					label={t("confirmPassword")}
					name={"confirmPassword"}
					inputType={InputType.PASSWORD}
					dependencies={["password"]}
					autoComplete={"off"}
					rules={[
						{
							required: true,
							message: t("confirmPasswordHint")
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue("password") === value) {
									return Promise.resolve()
								}
								return Promise.reject(new Error(t("passwordsDontMatch")))
							}
						})
					]}
				/>

				<Form.Item>
					<Button
						htmlType="submit"
						disabled={viewModel.isLoading}
						className="w-full gap-2 flex items-center justify-center"
						loading={viewModel.isLoading}
					>
						{t("continueBtn")}
					</Button>
				</Form.Item>
			</Form>
		</Col>
	)

	return (
		<>
			{context}
			<Layout className="layout-default layout-signin">
				<Header style={{ display: "flex", justifyContent: "space-between" }}>
					<div className="header-col header-brand">
						<img src={logo} alt="" />
					</div>
				</Header>
				<Content className="signin">
					<Row gutter={[24, 0]} justify="space-around">
						{viewModel.codeSent ? codeValidationForm : emailForm}
						<Col
							className="sign-img"
							style={{ padding: 12 }}
							xs={{ span: 24 }}
							lg={{ span: 12 }}
							md={{ span: 12 }}
						>
							<img src={signinbg} alt="" />
						</Col>
					</Row>
				</Content>
			</Layout>
		</>
	)
}

export default observer(ForgotView)
