import Sellers from "../pages/sellers/SellersView"
import { RoutePaths } from "./RoutePaths.enum"
import Categories from "../pages/categories/CategoriesView"
import Microsites from "../pages/microSites/MicrositesView"
import Tips from "../pages/tips/TipsView"
import MicrositesFormView from "../pages/microSites/MicrositesFormView"
import CategoryFormView from "../pages/categories/CategoryFormView"
import SellerFormView from "../pages/sellers/SellerFormView"
import SellerServicesFormView from "../pages/sellersServices/SellerServicesFormView"
import SellerServicesView from "../pages/sellersServices/SellerServicesView"
import VouchersView from "../pages/vouchers/VoucherView"
import VoucherFormView from "../pages/vouchers/VoucherFormView"
import SignInView from "../pages/signIn/SignInView"
import Settings from "../pages/settings/SettingsView"
import { ReactComponent as BillingIcon } from "../assets/icons/billing.svg"
import Users from "../pages/users/UsersView"
import UsersFormView from "../pages/users/UsersFormView"
import TipsFormView from "../pages/tips/TipsFormView"
import TransactionsView from "../pages/transactions/TransactionsView"
import Banners from "../pages/banners/BannersView"
import ReportsView from "../pages/reports/ReportsView"
import { ReportService } from "../../data/services/ReportService"
import { Roles } from "../../domain/enum/roles.enum"
import ForgotView from "../pages/forgot/ForgotView"
import TransactionFormView from "../pages/transactions/TransactionFormView"

const privateRoutes = [
	{
		name: "reports",
		Icon: BillingIcon,
		path: RoutePaths.REPORTS,
		Component: ReportsView,
		children: ({ reportService }: { reportService: ReportService }) =>
			reportService
				.getAdminEmbeddedReport()
				.then(data =>
					data.embedUrl.map(embed => ({ id: embed.reportId, label: embed.reportName, ...data, ...embed }))
				),
		roles: [Roles.ADMIN, Roles.PARTNER, Roles.SELLER]
	},
	{
		name: "banners",
		Icon: BillingIcon,
		path: RoutePaths.BANNERS,
		Component: Banners,
		roles: [Roles.ADMIN]
	},
	{
		name: "clusters",
		Icon: BillingIcon,
		path: RoutePaths.CATEGORIES,
		Component: Categories,
		roles: [Roles.ADMIN]
	},
	{
		path: RoutePaths.CATEGORIES_ADD_NEW,
		Component: CategoryFormView,
		roles: [Roles.ADMIN]
	},
	{
		name: "sellers",
		Icon: BillingIcon,
		path: RoutePaths.SELLERS,
		Component: Sellers,
		roles: [Roles.ADMIN, Roles.SELLER]
	},
	{
		path: RoutePaths.SELLERS_EDIT,
		Component: SellerFormView,
		roles: [Roles.ADMIN, Roles.SELLER]
	},
	{
		name: "services",
		Icon: BillingIcon,
		path: RoutePaths.SELLER_SERVICES,
		Component: SellerServicesView,
		roles: [Roles.ADMIN, Roles.SELLER]
	},
	{
		path: RoutePaths.SELLERS_SERVICES_ADD_NEW,
		Component: SellerServicesFormView,
		roles: [Roles.ADMIN, Roles.SELLER]
	},
	{
		name: "vouchers",
		Icon: BillingIcon,
		path: RoutePaths.VOUCHERS,
		Component: VouchersView,
		roles: [Roles.ADMIN]
	},
	{
		path: RoutePaths.VOUCHERS_ADD_NEW,
		Component: VoucherFormView,
		roles: [Roles.ADMIN]
	},
	{
		name: "microsites",
		Icon: BillingIcon,
		path: RoutePaths.MICROSITES,
		Component: Microsites,
		roles: [Roles.ADMIN]
	},
	{
		name: "users",
		Icon: BillingIcon,
		path: RoutePaths.USERS,
		Component: Users,
		roles: [Roles.ADMIN]
	},
	{
		path: RoutePaths.MICROSITES_ADD_NEW,
		Component: MicrositesFormView,
		roles: [Roles.ADMIN]
	},
	{
		path: RoutePaths.SETTINGS,
		Component: Settings,
		roles: [Roles.ADMIN]
	},
	{
		name: "tips",
		Icon: BillingIcon,
		path: RoutePaths.TIPS,
		Component: Tips,
		roles: [Roles.ADMIN, Roles.SELLER]
	},
	{
		path: RoutePaths.USERS_ADD_NEW,
		Component: UsersFormView,
		roles: [Roles.ADMIN]
	},
	{
		path: RoutePaths.TIPS_ADD_NEW,
		Component: TipsFormView,
		roles: [Roles.ADMIN, Roles.SELLER]
	},
	{
		name: "transactions",
		Icon: BillingIcon,
		path: RoutePaths.TRANSACTIONS,
		Component: TransactionsView,
		roles: [Roles.ADMIN, Roles.PARTNER, Roles.SELLER],
		index: true
	},
	{
		Icon: BillingIcon,
		path: RoutePaths.TRANSACTIONS_EDIT,
		Component: TransactionFormView,
		roles: [Roles.ADMIN]
	}
]

const publicRoutes = [
	{
		path: RoutePaths.SIGN_IN,
		Component: SignInView
	},
	{
		path: RoutePaths.FORGOT,
		Component: ForgotView
	}
]

const homePageRoute = RoutePaths.REPORTS

export { privateRoutes, publicRoutes, homePageRoute }
