interface ReportDetailsConstructorParams {
	reportId: string
	reportName: string
	embedUrl: string
	accessToken: string
	expiry: number
}
export class ReportDetailsModel {
	reportId: string
	reportName: string
	embedUrl: string
	accessToken: string
	expiry: number

	constructor({ reportId, reportName, embedUrl, accessToken, expiry }: ReportDetailsConstructorParams) {
		this.reportId = reportId
		this.reportName = reportName
		this.embedUrl = embedUrl
		this.accessToken = accessToken
		this.expiry = expiry
	}
}
interface ReportEmbedInfoConstructorParams {
	embedUrl: ReportDetailsModel[]
}
export class ReportEmbedInfoModel {
	embedUrl: ReportDetailsModel[]

	constructor({ embedUrl }: ReportEmbedInfoConstructorParams) {
		this.embedUrl = embedUrl
	}
	static fromRawJson = (rawJson: any): ReportEmbedInfoModel => {
		return new ReportEmbedInfoModel({
			...rawJson,
			embedUrl: rawJson.embedUrl?.map((detail: any) => new ReportDetailsModel(detail))
		})
	}
}
