export interface CategoryChildren {
	id?: string
	label: string
	position: string
	picture: string
	parentId: string
	countryId?: string
	highlighted?: boolean
}
export interface AssociatedService {
	id: number
	title: string
	sellerName: string
	weight: number
}
interface ContructorParams {
	id?: string
	label?: string
	labelEn?: string
	labelCa?: string
	position?: string
	picture?: string
	children?: CategoryChildren[]
	parentId?: string
	weight?: string
	countryId?: string
	highlighted?: boolean
	iconId?: number
	iconKey?: string
	iconPicture?: string
	micrositesAssociated?: { id: number; slug: string }[]
	servicesAssociated?: AssociatedService[]
}
;[]

export class Category {
	static MICROSITE = "999"
	public id?: string
	public label?: string
	public labelEn?: string
	public labelCa?: string
	public position?: string
	public picture?: string
	public children?: CategoryChildren[]
	public parentId?: string
	public weight?: string
	public countryId?: string
	public highlighted?: boolean
	public iconId?: number
	public iconKey?: string
	public iconPicture?: string
	public micrositesAssociated?: { id: number; slug: string }[]
	public servicesAssociated?: AssociatedService[]

	constructor(params: ContructorParams) {
		this.id = params.id
		this.label = params.label
		this.labelEn = params.labelEn
		this.labelCa = params.labelCa
		this.position = params.position
		this.parentId = params.parentId
		this.picture = params.picture
		this.children = params.children
		this.weight = params.weight
		this.countryId = params.countryId
		this.highlighted = params.highlighted
		this.iconId = params.iconId
		this.iconKey = params.iconKey
		this.iconPicture = params.iconPicture
		this.micrositesAssociated = params.micrositesAssociated
		this.servicesAssociated = params.servicesAssociated
	}
}
