import { Col, Divider, Modal, Row, Typography } from "antd"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import MainForm, { MainFormRef } from "../../../components/Form/MainForm"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { createRef, useEffect, useState } from "react"
import { FormField } from "../../../components/Form/FormField"
import { SellerServicesViewModel } from "../../sellersServices/SellerServicesViewModel"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { MicrositesViewModel } from "../MicrositesViewModel"
import { SellersViewModel } from "../../sellers/SellersViewModel"
import { ServiceStatus } from "../../../../domain/enum/serviceStatus.enum"

type InitialData = {
	id?: string
	weight: string
	highlighted: string
	title: string
	new: boolean
	sellerName: string
}
export interface ServiceSessionModalProps {
	onConfirm?: (modalData: InitialData) => void
	onCancel?: () => void
	visible: boolean
	editting?: boolean
}

const MicrositesServicesModal = ({ visible, onConfirm, onCancel }: ServiceSessionModalProps) => {
	const { t } = useTranslation("microsites", { keyPrefix: "form.servicesInfo.serviceModal" })
	const sellerServicesViewModel = useContainerInjection<SellerServicesViewModel>("SellerServicesViewModel")
	const sellersViewModel = useContainerInjection<SellersViewModel>("SellersViewModel")
	const micrositeViewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")
	const initialModalData: InitialData = {
		id: undefined,
		weight: "",
		highlighted: "0",
		title: "",
		new: true,
		sellerName: ""
	}
	const [modalData, setModalData] = useState<InitialData>(initialModalData)
	const formRef = createRef<MainFormRef>()

	useEffect(() => {
		if (sellerServicesViewModel.sellersServices?.length === 0) {
			sellerServicesViewModel.fetchSellersServices()
		}
		if (sellersViewModel.sellers?.length === 0) {
			sellersViewModel.fetchSellers()
		}
		setModalData(initialModalData)
	}, [visible])

	const micrositeServices = micrositeViewModel.formData?.services

	return (
		<Modal
			title={
				<div style={{ position: "relative", bottom: 24 }}>
					<Typography.Title level={5}>{t("modalTitle")}</Typography.Title>
					<Divider style={{ marginBottom: 0 }} />
				</div>
			}
			centered
			style={{ overflowY: "auto", padding: "60px 0px" }}
			open={visible}
			onOk={async () => {
				await formRef.current?.validateFields()
				onConfirm && onConfirm(modalData)
			}}
			onCancel={() => onCancel && onCancel()}
			width={600}
			okText={t("confirm")}
			cancelText={t("cancel")}
		>
			<MainForm
				initialValues={modalData}
				hideFormButtons
				key={"micrositeServiceModal"}
				onChangeFormValues={formData => {
					if (JSON.stringify(modalData) !== JSON.stringify(formData)) {
						setModalData({
							...modalData,
							...formData
						})
					}
				}}
				className="flex flex-col justify-center items-center"
				ref={formRef}
			>
				<Row gutter={[24, 0]} className="w-full">
					<Col md={24} xl={24} xs={24}>
						<Row gutter={[24, 0]}>
							<Col xl={18} md={18} xs={20} className="md:mx-auto">
								<FormField.SelectSearch
									key={"id"}
									label={t("service")}
									name={"id"}
									asyncFetchSearchFunction={async searchValue =>
										await sellerServicesViewModel.searchServices(searchValue)
									}
									mandatory
									value={modalData?.id}
									placeholder={t("servicePlaceholder").toString()}
									onChange={serviceSelected => {
										const sellerName = sellerServicesViewModel.searchedServices.find(
											// @ts-ignore
											service => service.id === serviceSelected?.value
										)?.name
										setModalData({
											...modalData,
											// @ts-ignore
											id: serviceSelected?.value,
											// @ts-ignore
											title: serviceSelected?.title,
											// @ts-ignore
											sellerName
										})
									}}
									options={sellerServicesViewModel.searchedServices
										.filter(
											sellerService =>
												sellerService.status === ServiceStatus.ACTIVE &&
												micrositeServices?.every(service => {
													return Number(service.id) !== sellerService.id
												})
										)
										.map(service => ({
											label: `${service.id} - ${service.title}` || "",
											value: service.id || "",
											title: service.title || ""
										}))}
								/>
							</Col>
						</Row>
						<Row gutter={[24, 0]}>
							<Col xl={9} md={9} xs={20} className=" md:ms-auto">
								<FormField.Input
									inputType={InputType.NUMBER}
									key={"weight"}
									label={t("weight")}
									name={"weight"}
									mandatory
								/>
							</Col>
							<Col xl={9} md={9} xs={20} className="md:me-auto">
								<FormField.SelectSearch
									mandatory
									key={"highlighted"}
									label={t("highlighted")}
									name={"highlighted"}
									value={modalData?.highlighted}
									onChange={highlighted =>
										// @ts-ignore
										setModalData({ ...modalData, highlighted: highlighted.value })
									}
									options={[
										{ value: "1", label: "SI" },
										{ value: "0", label: "NO" }
									]}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</MainForm>
		</Modal>
	)
}

export default observer(MicrositesServicesModal)
