const config = {
	api: {
		legacyBaseEndpoint: process.env.REACT_APP_API_BASE_ENDPOINT_LEGACY,
		baseEndpoint: process.env.REACT_APP_API_BASE_ENDPOINT
	},
	webAppAdmin: process.env.REACT_APP_ADMIN_URL,
	assets: process.env.REACT_APP_SPORTTIPS_ASSET_SERVICE_HOSTNAME,
	google: {
		clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
	},
	ui: {
		componentsConfig: {
			searchInputDelay: 300,
			dataTableRecordsLimit: 200,
			dataTablePageSize: 20
		},
		users: {
			pageSize: 20,
			recordsPerRequest: 200,
			profilePicture: {
				width: 1280,
				height: 720
			}
		},
		services: {
			pageSize: 20,
			recordsPerRequest: 200,
			cover: {
				width: 250,
				height: 161
			},
			images: {
				qtyLimit: 10,
				width: 680,
				height: 380
			}
		},
		tips: {
			limits: {
				imageSizeMB: 10,
				videoSizeMB: 500
			}
		},
		banners: {
			main: {
				desktop: {
					imageRatio: 3846 / 1000
				},
				mobile: {
					imageRatio: 1412 / 710
				}
			},
			secondary: {
				large: {
					desktop: {
						imageRatio: 1196 / 250
					},
					mobile: {
						imageRatio: 710 / 301
					}
				},
				short: {
					desktop: {
						imageRatio: 709 / 301
					},
					mobile: {
						imageRatio: 590 / 250
					}
				}
			}
		},
		sellers: {
			logo: {
				width: 48,
				height: 48
			}
		}
	},
	tax: {
		fiscalCountries: ["ES", "MX"]
	}
}

export default config
