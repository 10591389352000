import { ServiceStatus } from "../enum/serviceStatus.enum"

interface ContructorParams {
	city: string
	address?: string
	contact: string
	contact_telf: string
	email: string
	id: number
	name: string
	title: string
	weight: number
	status: ServiceStatus
}

export class SellerService {
	public city: string
	public address?: string
	public contact: string
	public contact_telf: string
	public email: string
	public id: number
	public name: string
	public title: string
	public weight: number
	public status: ServiceStatus

	constructor(params: ContructorParams) {
		this.city = params.city
		this.address = params.address
		this.contact = params.contact
		this.contact_telf = params.contact_telf
		this.email = params.email
		this.id = params.id
		this.name = params.name
		this.title = params.title
		this.weight = params.weight
		this.status = params.status
	}
}
