export enum RoutePaths {
	USERS = "/users",
	USERS_ADD_NEW = "/users/addNew",
	SELLER_SERVICES = "/services",
	SELLERS_SERVICES_ADD_NEW = "/services/addNew",
	VOUCHERS = "/vouchers",
	VOUCHERS_ADD_NEW = "/vouchers/addNew",
	SELLERS = "/sellers",
	SELLERS_EDIT = "/sellers/edit",
	CATEGORIES = "/clusters",
	CATEGORIES_ADD_NEW = "/clusters/addNew",
	MICROSITES = "/microsites",
	MICROSITES_ADD_NEW = "/microsites/addNew",
	// SIGN_UP = "/sign-up",
	SIGN_IN = "/sign-in",
	FORGOT = "/forgot",
	CALENDAR = "/calendar",
	SETTINGS = "/settings",
	TIPS = "/tips",
	TIPS_ADD_NEW = "/tips/addNew",
	TRANSACTIONS = "/transactions",
	TRANSACTIONS_EDIT = "/transactions/edit",
	BANNERS = "/banners",
	REPORTS = "/reports"
}
