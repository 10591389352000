import { observer } from "mobx-react"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { PowerBIEmbed } from "powerbi-client-react"
import { models, Report } from "powerbi-client"
import { ReportsViewModel } from "./ReportsViewModel"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator"
import { colors } from "../../assets/styles/appStyles"
import { ReportDetails } from "../../../domain/entities/ReportEmbedInfo"

const ReportsView = () => {
	const viewModel = useContainerInjection<ReportsViewModel>("ReportsViewModel")
	const location = useLocation()
	const [reportEmbedData, setReportEmbedData] = useState<ReportDetails | null>(null)
	useEffect(() => {
		if (!location.state?.menuItem) {
			loadReport()
		} else {
			setReportEmbedData(location.state.menuItem)
		}
	}, [location.state])
	const loadReport = async () => {
		const embedInfo = await viewModel.getReports()
		const firstReport = embedInfo.embedUrl[0]
		setReportEmbedData(firstReport)
	}
	return (
		<div style={{ height: "100%" }}>
			<div className="report-header"></div>
			{reportEmbedData ? (
				<PowerBIEmbed
					embedConfig={{
						type: "report",
						id: reportEmbedData.reportId,
						embedUrl: reportEmbedData.embedUrl,
						accessToken: reportEmbedData.accessToken,
						tokenType: models.TokenType.Embed,
						settings: {
							panes: {
								filters: {
									expanded: false,
									visible: false
								}
							},
							background: models.BackgroundType.Transparent
						}
					}}
					eventHandlers={
						new Map([
							[
								"loaded",
								function () {
									console.log("Report loaded")
								}
							],
							[
								"rendered",
								function () {
									console.log("Report rendered")
								}
							],
							[
								"error",
								function (event) {
									console.log(event?.detail)
								}
							],
							["visualClicked", () => console.log("visual clicked")],
							["pageChanged", event => console.log(event)]
						])
					}
					cssClassName={"embedded-admin-report"}
					getEmbeddedComponent={embeddedReport => {
						viewModel.setEmbeddedAdminReport(embeddedReport as Report)
					}}
				/>
			) : (
				<ActivityIndicator size="xl" color={colors.activity} />
			)}
		</div>
	)
}

export default observer(ReportsView)
