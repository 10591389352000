import { Transaction } from "../../domain/entities/Transaction"
import { TransactionsRepository } from "../../domain/repositories/TransactionsRepository"
import { Filter } from "../../domain/types/transactions/Filter"
import { UploadTransactionsResponse } from "../../domain/useCases/transactions/uploadTransactions/response"
import { CreateTransactionDTO } from "../dto/CreateTransaction.dto"
import { TransactionsService } from "../services/TransactionsService"

export class TransactionsRepositoryImpl implements TransactionsRepository {
	private _transactionsService

	constructor({ TransactionsService }: { TransactionsService: TransactionsService }) {
		this._transactionsService = TransactionsService
	}

	createTransaction(transaction: CreateTransactionDTO): Promise<number> {
		return this._transactionsService.createTransaction(transaction)
	}

	updateTransaction(transaction: Transaction, sendEmails: boolean): Promise<void> {
		return this._transactionsService.updateTransaction(transaction, sendEmails)
	}

	public async uploadTransactions(file: FormData): Promise<UploadTransactionsResponse> {
		return await this._transactionsService.uploadTransactions(file)
	}

	public async getTransactions(offset?: number, limit?: number, filter?: Filter) {
		return await this._transactionsService.getTransactions(offset, limit, filter)
	}

	public async downloadTransactions(offset: number, limit: number, filter?: Filter): Promise<Blob> {
		return this._transactionsService.downloadTransactions(offset, limit, filter)
	}
}
