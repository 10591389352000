import { Link } from "react-router-dom"
import TABLE_COLUMNS from "../const/TABLE_COLUMNS"
import { Tooltip } from "antd"
import { EditFilled } from "@ant-design/icons"
import { Seller } from "../../../../domain/entities/Seller"
import { RoutePaths } from "../../../navigation/RoutePaths.enum"
import { observer } from "mobx-react"
import DataTable from "../../../components/DataTable/DataTable"
import { toJS } from "mobx"
import { useTranslation } from "react-i18next"
import { SellersViewModel } from "../SellersViewModel"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"

interface SellerTableProps {
	data: Partial<Seller>[]
	loading?: boolean
}

const SellerTable = ({ data, loading }: SellerTableProps) => {
	const viewModel = useContainerInjection<SellersViewModel>("SellersViewModel")
	const { t } = useTranslation("toolTips")
	return (
		<DataTable
			loading={loading}
			tableConfig={{
				sort: viewModel.tableConfig.sort,
				pageSize: viewModel.tableConfig.pageSize
			}}
			renderCustomLoadingComponent={() => <DataTableRowsSkeleton size="lg" />}
			onChange={data => {
				data.sorter &&
					viewModel.setTableConfig({ sort: { order: data.sorter.order, field: data.sorter.field } })
			}}
			onChangePage={(page, pageSize: number, lastPageFetched) => viewModel.setTableConfig({ pageSize })}
			columns={TABLE_COLUMNS()}
			dataSource={data.map((seller, idx) => ({
				key: seller.id,
				id: seller.id,
				userEmail: seller.userEmail,
				seller: seller.sellerName,
				contact: seller.contact,
				nif: seller.nif,
				iban: seller.iban,
				web: seller?.web ? (
					<a href={seller?.web} target="_blank">
						{seller?.web}
					</a>
				) : (
					"N/A"
				),
				editButton: (
					<div className="ant-employed">
						<Link to={RoutePaths.SELLERS_EDIT} state={{ seller: toJS(seller) }}>
							<Tooltip title={t("edit")}>
								<EditFilled />
							</Tooltip>
						</Link>
					</div>
				)
			}))}
		/>
	)
}

export default observer(SellerTable)
