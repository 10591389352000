import { Form, FormItemProps } from "antd"
import { CustomBasicSelectorProps, CustomBasicSelector as Selector } from "../CustomSelectors/CustomBasicSelector"
import { useTranslation } from "react-i18next"
import { calcFormFieldValidationRule } from "../../utils/calFormFieldValidationRule"

export interface FormSelectorProps extends ExtendedProps {
	disabled?: boolean
	placeholder?: string
	mandatory?: boolean | { validator?: boolean }
	validationMessage?: string
	customFieldValidation?: { status: FormItemProps["validateStatus"]; message: string }
}

type ExtendedProps = FormItemProps & Omit<CustomBasicSelectorProps, "mandatory">

const FormSelector = ({
	mandatory,
	validationMessage,
	customFieldValidation,
	options,
	toolTipTitle,
	onChange,
	allowClear,
	onClear,
	...props
}: FormSelectorProps) => {
	const { t } = useTranslation("mainForm", { keyPrefix: "validations" })
	return (
		<Form.Item
			labelCol={{ span: 24 }}
			validateStatus={customFieldValidation?.message ? customFieldValidation?.status : undefined}
			rules={calcFormFieldValidationRule(mandatory, validationMessage ?? t("mandatoryField"))}
			{...props}
		>
			<Selector
				onClear={onClear}
				allowClear={allowClear}
				options={options}
				toolTipTitle={toolTipTitle}
				onChange={onChange}
				loading={props.loading}
				disabled={props.disabled}
				placeholder={props.placeholder}
			/>
		</Form.Item>
	)
}

export default FormSelector
