import { DeleteFilled } from "@ant-design/icons"
import DataTable from "../../../components/DataTable/DataTable"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import { CategoriesViewModel } from "../CategoriesViewModel"
import CATEGORY_SERVICE_COLUMNS from "../const/CATEGORY_SERVICE_COLUMNS"
import { colors } from "../../../assets/styles/appStyles"
import { AssociatedService } from "../../../../domain/entities/Category"

export interface CategoryServicesTableProps {
	services: AssociatedService[]
	onChange: (serviceId: number, weight: number) => void
	handleDeleteService: (serviceId: number) => void
}

const CategoryServicesTable = ({ handleDeleteService, services, onChange }: CategoryServicesTableProps) => {
	const viewModel = useContainerInjection<CategoriesViewModel>("CategoriesViewModel")

	return (
		<>
			<DataTable
				loading={viewModel.isLoadingDetail}
				renderCustomLoadingComponent={() => <DataTableRowsSkeleton />}
				editableCells={{
					handleSave: record => onChange && onChange(record.id, Number(record.weight))
				}}
				columns={CATEGORY_SERVICE_COLUMNS()}
				dataSource={
					services?.map(service => ({
						key: service.id,
						id: service.id,
						title: service.title,
						weight: service.weight,
						seller: service.sellerName,
						actions: (
							<div key={service.id}>
								<DeleteFilled
									className="hover:cursor-pointer"
									style={{ fontSize: "1.2rem", color: colors.error }}
									onClick={() => handleDeleteService(service.id)}
								/>
							</div>
						)
					})) ?? []
				}
			/>
		</>
	)
}

export default CategoryServicesTable
