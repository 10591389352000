import { jwtDecode } from "jwt-decode"
import { Login } from "../../domain/entities/login"
import { Endpoint } from "../network/endpoint"
import { HttpManager } from "../network/interface/HttpManager"
import { Jwt } from "../../domain/types/Jwt.type"
import { Roles } from "../../domain/enum/roles.enum"
import { AxiosResponse } from "axios"

const allowedRoles = [Roles.ADMIN, Roles.PARTNER, Roles.SELLER]
export class AuthService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	public async emailSignIn(loginData: Login): Promise<any> {
		const response = await this._http.requestNewApi({
			method: "POST",
			url: Endpoint.LOGIN,
			data: loginData
		})
		const { user } = jwtDecode<Jwt>(response.data.access_token)
		if (!allowedRoles.reduce((allowed, role) => allowed || user.roles.includes(role), false)) throw new Error()
		localStorage.setItem("legacyToken", user.token)
		this._http.setAuthToken(response.data.access_token)
		this._http.setLegacyAuthToken(user.token)
		return response
	}

	public async googleSignIn(code: string): Promise<any> {
		const response = await this._http.get(Endpoint.GOOGLE_LOGIN, { params: { code } })
		return response
	}

	public async forgotPassword(email: string, forgotUrl: string): Promise<AxiosResponse<{ message: string }>> {
		return await this._http.requestNewApi<{ message: string }>({
			method: "GET",
			url: Endpoint.FORGOT_PASSWORD,
			params: { email, forgotUrl }
		})
	}

	public async logout(): Promise<void> {
		throw new Error("Method not implemented.")
	}
}
