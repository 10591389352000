import { Col, Row } from "antd"
import { Seller } from "../../../../domain/entities/Seller"
import { useTranslation } from "react-i18next"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { FormField } from "../../../components/Form/FormField"
import config from "../../../../config/config"

interface Props {
	inputDisabled?: boolean
	onChangeText?: (value: Partial<Seller>) => void
}

const AdministrativeInformationFormSection = ({ inputDisabled, onChangeText }: Props) => {
	const { t } = useTranslation("sellers", { keyPrefix: "form.fields" })
	const { t: tCountries } = useTranslation("countries")
	return (
		<>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						disabled={inputDisabled}
						key={"fiscalName"}
						label={t("fiscalName")}
						name={"fiscalName"}
						onChange={(fiscalName: string) => onChangeText && onChangeText({ fiscalName })}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Select
						disabled={inputDisabled}
						mandatory
						key={"fiscalCountry"}
						label={t("fiscalCountry")}
						name={"fiscalCountry"}
						onChange={(fiscalCountry: string) => onChangeText && onChangeText({ fiscalCountry })}
						options={config.tax.fiscalCountries.map(country => ({
							value: country,
							label: tCountries(country),
							key: country
						}))}
					/>
				</Col>
			</Row>

			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						disabled={inputDisabled}
						inputType={InputType.NUMBER}
						key={"paymentDays"}
						label={t("paymentDays")}
						name={"paymentDays"}
						onChange={(paymentDays: string) => onChangeText && onChangeText({ paymentDays })}
						style={{ marginBottom: "9px" }}
						customFieldPosition={"relative"}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						disabled={inputDisabled}
						inputType={InputType.NUMBER}
						key={"feePercentage"}
						label={t("feePercentage")}
						name={"feePercentage"}
						preFix={"%"}
						onChange={(feePercentage: string) => onChangeText && onChangeText({ feePercentage })}
						style={{ marginBottom: "9px" }}
						customFieldPosition={"relative"}
					/>
				</Col>
			</Row>

			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						disabled={inputDisabled}
						key={"nif"}
						label={t("nif")}
						name={"nif"}
						mandatory
						onChange={(nif: string) => onChangeText && onChangeText({ nif })}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						disabled={inputDisabled}
						key={"iban"}
						label={t("iban")}
						name={"iban"}
						mandatory
						onChange={(iban: string) => onChangeText && onChangeText({ iban })}
					/>
				</Col>
			</Row>
		</>
	)
}

export default AdministrativeInformationFormSection
