import { VoucherRepository } from "../../../repositories/voucherRepository"
import { UseCase } from "../../useCase"
import { GetVoucherRulesRequest } from "./request"
import { GetVoucherRulesResponse } from "./response"

export class GetVoucherRulesUseCase implements UseCase<GetVoucherRulesRequest, GetVoucherRulesResponse> {
	private _repository
	constructor({ VoucherRepository }: { VoucherRepository: VoucherRepository }) {
		this._repository = VoucherRepository
	}

	exec(filter: GetVoucherRulesRequest) {
		return this._repository.getVoucherRules(filter)
	}
}
