import { UseCase } from "../../useCase"
import { TransactionsRepository } from "../../../repositories/TransactionsRepository"
import { CreateTransactionRequest } from "./request"
import { CreateTransactionResponse } from "./response"

export class CreateTransactionUseCase implements UseCase<CreateTransactionRequest, CreateTransactionResponse> {
	private _repository
	constructor({ TransactionsRepository }: { TransactionsRepository: TransactionsRepository }) {
		this._repository = TransactionsRepository
	}

	exec(transaction: CreateTransactionRequest) {
		return this._repository.createTransaction(transaction)
	}
}
