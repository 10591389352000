import { ReportEmbedInfo } from "../../domain/entities/ReportEmbedInfo"
import { ReportEmbedInfoModel } from "../models/ReportEmbedInfoModel"
import { Endpoint } from "../network/endpoint"
import { HttpManager } from "../network/interface/HttpManager"

export class ReportService {
	private _http: HttpManager
	private _ongoingRequest?: Promise<ReportEmbedInfo>

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	async getAdminEmbeddedReport() {
		if (this._ongoingRequest) return this._ongoingRequest

		this._ongoingRequest = this._http
			.requestNewApi({
				method: "GET",
				url: Endpoint.ADMIN_REPORT
			})
			.then(response => {
				this._ongoingRequest = undefined
				return ReportEmbedInfoModel.fromRawJson(response.data)
			})
		return this._ongoingRequest
	}
}
