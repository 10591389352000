import { Col, Row, Typography } from "antd"
import { SellerServiceDetail } from "../../../../domain/entities/SellerServiceDetail"
import Switch from "../../../components/Switch/Switch"
import { useTranslation } from "react-i18next"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { SellerServicesViewModel } from "../SellerServicesViewModel"
import ServiceConfigSectionSkeleton from "../../../styledComponents/LoadingSkeletons/ServiceConfigSectionSkeleton"
import { FormField } from "../../../components/Form/FormField"
import { Country } from "../../../../domain/entities/Country"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { AuthStore } from "../../../stores/AuthStore"

interface ServiceConfigurationFormSectionProps {
	onChange: (value: any) => void
	disabled?: boolean
	values?: Partial<SellerServiceDetail>
}

export const ServiceConfigurationFormSection = ({
	onChange,
	disabled,
	values
}: ServiceConfigurationFormSectionProps) => {
	const { t } = useTranslation("services", { keyPrefix: "form.configInfo" })
	const { t: tCountries } = useTranslation("countries")
	const viewModel = useContainerInjection<SellerServicesViewModel>("SellerServicesViewModel")
	const { isAdmin } = useContainerInjection<AuthStore>("AuthStore")

	if (viewModel.isLoadingDetail) {
		return <ServiceConfigSectionSkeleton />
	}

	return (
		<Row gutter={[24, 0]}>
			<Col xl={24} md={24} xs={24}>
				<Switch
					title={t("online").toString()}
					checked={values?.isOnline}
					onChange={isOnline => onChange && onChange({ isOnline })}
					disabled={disabled}
				/>
				<Switch
					title={t("presencial").toString()}
					checked={values?.isPresential}
					onChange={isPresential => onChange && onChange({ isPresential })}
					disabled={disabled}
				/>
				{/* <Switch
          title={t('isElite').toString()}
          checked={values?.isElite}
          onChange={(isElite) =>
            onChange && onChange({ isElite })
          }
          disabled={disabled}
        /> */}
				{/* <Switch
          title={t('isFeatured').toString()}
          checked={values?.isFeaturedByUser}
          onChange={(isFeaturedByUser) =>
            onChange && onChange({ isFeaturedByUser })
          }
          disabled={disabled}
        /> */}
				<Switch
					title={t("isFoundation").toString()}
					checked={values?.isFoundation}
					onChange={isFoundation => onChange && onChange({ isFoundation })}
					disabled={disabled}
				/>
				<Switch
					title={t("highlighted").toString()}
					checked={values?.highlighted}
					onChange={highlighted => onChange && onChange({ highlighted })}
					disabled={disabled || !isAdmin}
				/>
				<Switch
					title={t("crossSelling").toString()}
					checked={values?.crossSelling}
					onChange={crossSelling => onChange && onChange({ crossSelling })}
					disabled={disabled || !isAdmin}
				/>
				<Switch
					title={t("askForInformation").toString()}
					checked={values?.askForInformation}
					onChange={val => onChange && onChange({ askForInformation: val })}
					disabled={disabled || !isAdmin}
				/>
				<FormField.SelectSearch
					label={t("country").toString()}
					disabled={disabled}
					placeholder={"Select a seller"}
					name="country"
					containerStyles={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
					style={{ display: "flex" }}
					//@ts-ignore
					value={values?.countryId}
					options={viewModel.countries.map((country: Country) => ({
						value: country.id,
						label: tCountries(country.id),
						key: country.id
					}))}
					onChange={country => {
						//@ts-ignore
						onChange && onChange({ countryId: country?.value })
					}}
				/>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "baseline",
						marginTop: "1rem"
					}}
				>
					<Typography.Text>{t("weight")} :</Typography.Text>
					<FormField.Input
						key={"weight"}
						style={{ width: "25%" }}
						inputType={InputType.NUMBER}
						name={"weight"}
						onChange={weight => onChange && onChange({ weight: Number(weight) })}
						disabled={!isAdmin}
					/>
				</div>
			</Col>
		</Row>
	)
}
