import { SellerServicesRepository } from "../../domain/repositories/sellerServicesRepository"
import { Language } from "../../locale/interfaces/Translation"
import { SellerServicesDetailModel } from "../models/SellerServicesDetailModel"
import { SellerServicesService } from "../services/SellerServicesService"
import { SellerServiceDetail } from "../../domain/entities/SellerServiceDetail"
import { SellerService } from "../../domain/entities/SellerService"
import { SellerServiceCalendar } from "../../domain/entities/SellerServiceCalendar"
import { UploadMultipleSellerServicePictureResponse } from "../../domain/useCases/sellersServices/uploadMultipleSellerServicePicturesUseCase/response"
import { ServiceStatus } from "../../domain/enum/serviceStatus.enum"

export class SellerServicesRepositoryImpl implements SellerServicesRepository {
	private _sellerServicesService
	constructor({ SellerServicesService }: { SellerServicesService: SellerServicesService }) {
		this._sellerServicesService = SellerServicesService
	}

	public async getSellerServices(offset: number, limit: number, status?: string): Promise<SellerService[]> {
		const sellerServices = await this._sellerServicesService.getSellerServices(offset, limit, status)
		return sellerServices.map(sellerService => sellerService.toDomain())
	}

	// New API endpoint
	public async getServiceDetail(id: string): Promise<SellerServiceDetail> {
		const detail = await this._sellerServicesService.getServiceDetail(id)
		return detail.toDomain()
	}

	public createSellerService(sellerService: SellerServiceDetail): Promise<string> {
		return this._sellerServicesService.createSellerService(SellerServicesDetailModel.fromDomain(sellerService))
	}

	public createServiceContent(sellerService: SellerServiceDetail): Promise<number> {
		return this._sellerServicesService.createServiceContent(SellerServicesDetailModel.fromDomain(sellerService))
	}

	public async updateSellerService(sellerService: SellerServiceDetail): Promise<Partial<SellerServiceDetail>> {
		const response = await this._sellerServicesService.updateSellerService(
			SellerServicesDetailModel.fromDomain(sellerService)
		)
		return response.toDomain()
	}

	public async updateSellerServiceStatus(id: number, status: ServiceStatus): Promise<void> {
		return this._sellerServicesService.deleteSellerService(id, status)
	}

	public async uploadServiceImages(
		serviceId: string,
		files: FormData
	): Promise<UploadMultipleSellerServicePictureResponse> {
		return await this._sellerServicesService.uploadServiceImages(serviceId, files)
	}

	public async searchSellerServices(expression: string, offset?: number, limit?: number): Promise<SellerService[]> {
		const response = await this._sellerServicesService.searchSellerServices(expression, offset, limit)
		return response.map(sellerService => sellerService.toDomain())
	}

	public async getSellerServiceExcel(): Promise<Blob> {
		const response = await this._sellerServicesService.getSellerServiceExcel()
		return response
	}
}
