import { User } from "../../domain/entities/User"

interface ContructorParams {
	id: string
	name: string
	username: string
	website: string
	birthdate: string
	picture: string
	email: string
	language: string
	phoneNumber: string
	city: string
	goal: string
	bio: string
	status: string
	roles: string[]
	password?: string
	endorserTypeKey?: string | null
	roleAttributes?: { microsites?: number[]; reports?: string[]; marketplaces?: string[] }
	country?: string
	microsite?: string
}

export class UserModel {
	public id: string
	public name: string
	public username: string
	public website: string
	public birthdate: string
	public picture: string
	public email: string
	public language: string
	public phoneNumber: string
	public city: string
	public goal: string
	public bio: string
	public status: string
	public roles: string[]
	public password?: string
	public endorserTypeKey?: string | null
	public roleAttributes?: { microsites?: number[]; reports?: string[]; marketplaces?: string[] }
	public country?: string
	public microsite?: string

	constructor(params: ContructorParams) {
		this.id = params.id
		this.name = params.name
		this.username = params.username
		this.website = params.website
		this.birthdate = params.birthdate
		this.picture = params.picture
		this.email = params.email
		this.language = params.language
		this.phoneNumber = params.phoneNumber
		this.city = params.city
		this.goal = params.goal
		this.bio = params.bio
		this.status = params.status
		this.roles = params.roles
		this.password = params.password
		this.endorserTypeKey = params.endorserTypeKey
		this.roleAttributes = params.roleAttributes
		this.country = params.country
		this.microsite = params.microsite
	}

	static fromRawJson = (rawJson: any): UserModel => {
		const {
			id,
			name,
			username,
			website,
			birthdate,
			picture,
			email,
			language,
			phoneNumber,
			city,
			goal,
			bio,
			status,
			roles,
			endorserTypeKey,
			roleAttributes,
			country,
			microsite
		} = rawJson

		return new UserModel({
			id,
			name,
			username,
			website,
			birthdate,
			picture,
			email,
			language,
			phoneNumber,
			city,
			goal,
			bio,
			status,
			roles,
			endorserTypeKey,
			roleAttributes,
			country,
			microsite
		})
	}

	static fromDomain = (data: User): UserModel => {
		let password: string | undefined = data.password
		if (!password || password == User.DEFAULT_PASSWORD) {
			password = undefined
		}

		return new UserModel({
			...data,
			username: data.userName,
			website: data.webSite,
			roles: [data.role],
			password,
			endorserTypeKey: data.endorserTypeKey?.toUpperCase() || null
		})
	}
}

declare module "./UserModel" {
	interface UserModel {
		toDomain(): User
	}
}

UserModel.prototype.toDomain = function (): User {
	const data = this
	return new User({
		...data,
		webSite: data.website,
		userName: data.username,
		role: data.roles?.[0],
		endorserTypeKey: data.endorserTypeKey?.toLowerCase()
	})
}
