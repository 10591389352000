import { makeObservable, observable } from "mobx"
import { ForgotPasswordUseCase } from "../../../domain/useCases/auth/forgot"
import { LoginUseCase } from "../../../domain/useCases/auth/signIn"
import { AuthStore } from "../../stores/AuthStore"
import { Login } from "../../../domain/entities/login"

interface ContructorParams {
	ForgotPasswordUseCase: ForgotPasswordUseCase
	LoginUseCase: LoginUseCase
	AuthStore: AuthStore
}

export class ForgotViewModel {
	isLoading: boolean = false
	codeSent: boolean = false
	private _forgotPasswordUseCase
	private _loginUseCase
	private _authStore

	constructor({ ForgotPasswordUseCase, LoginUseCase, AuthStore }: ContructorParams) {
		this._forgotPasswordUseCase = ForgotPasswordUseCase
		this._loginUseCase = LoginUseCase
		this._authStore = AuthStore
		makeObservable(this, {
			isLoading: observable,
			codeSent: observable
		})
	}

	public async triggerPasswordRecovery(email: string) {
		try {
			this.setLoading(true)
			await this._forgotPasswordUseCase.exec({ email })
			this.setCodeSent(true)
		} catch (error) {
			throw error
		} finally {
			this.setLoading(false)
		}
	}

	public async login(loginData: Login) {
		try {
			this.setLoading(true)
			const data = await this._loginUseCase.exec(loginData)
			this._authStore.onLogin(data.access_token, true)
			return this._authStore.userData
		} catch (error) {
			throw error
		} finally {
			this.setLoading(false)
		}
	}

	private setLoading(state: boolean) {
		this.isLoading = state
	}

	public setCodeSent(sent: boolean) {
		this.codeSent = sent
	}
}
