import { Button, Col, Row, Typography } from "antd"
import TipsTable from "./components/TipsTable"
import { useNavigate } from "react-router-dom"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { TipsViewModel } from "./TipsViewModel"
import { RoutePaths } from "../../navigation/RoutePaths.enum"
import { observer } from "mobx-react"
import SearchFilterField from "../../components/SearchFilterField/SearchFilterField"
import { useTranslation } from "react-i18next"
import { Card } from "../../styledComponents/CustomCard/Card"
import { Tip } from "../../../domain/entities/Tip"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import config from "../../../config/config"
import { AuthStore } from "../../stores/AuthStore"

const { Title } = Typography

const TipsView = () => {
	const { t } = useTranslation("tips")
	const viewModel = useContainerInjection<TipsViewModel>("TipsViewModel")
	const navigate = useNavigate()
	const { isAdmin } = useContainerInjection<AuthStore>("AuthStore")
	const filterFunction = (value: Partial<Tip>, searchInput: string | undefined) => {
		return searchInput && isNaN(parseInt(searchInput))
			? searchInput
				? !(
						// TODO: Check if Tips schema will be string or number
						(
							value?.id?.toString().indexOf(searchInput) === -1 &&
							value?.description?.toLowerCase().indexOf(searchInput.toLowerCase()) === -1
						)
				  )
				: true
			: searchInput
			? !(value?.id?.toString().indexOf(searchInput) === -1)
			: true
	}

	const initialTip: Partial<Tip> = {
		microsites: [],
		countries: [],
		description: undefined,
		published: isAdmin,
		mediaUrl: undefined,
		mediaPreview: undefined,
		user: undefined,
		mediaType: viewModel.mediaType[0].value
	}

	useUpdateEffect(() => {
		viewModel.fetchPaginatedTips()
	}, [viewModel.pagination])

	return (
		<>
			<SearchFilterField<Tip>
				value={viewModel.searchValue}
				placeholder={t("searchBar").toString()}
				searchData={viewModel.tips}
				filterFunction={filterFunction}
				onChange={value => {
					viewModel.setSearchedTips(value)
				}}
				inputDelay={config.ui.componentsConfig.searchInputDelay}
				onChangeText={value => {
					viewModel.searchValue = value
					viewModel.searchTips(value)
				}}
				style={{ marginBottom: "10px" }}
			/>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs={24} xl={24}>
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div style={{ display: "flex", alignItems: "center" }}>
									<Title style={{ marginTop: 8 }} level={4}>
										{t("title")}
									</Title>
									<Button
										style={{ marginLeft: "1.5vw" }}
										type="primary"
										onClick={() =>
											navigate(RoutePaths.TIPS_ADD_NEW, {
												state: { tip: initialTip }
											})
										}
									>
										{t("addNew")}
									</Button>
								</div>
							}
						>
							<TipsTable
								data={viewModel.searchedTips}
								loading={viewModel.isLoading}
								onChangePage={(page, pageSize, lastPageFetched) => {
									if (viewModel.searchValue) return
									if (page >= lastPageFetched - 1 && viewModel.hasNextPage && !viewModel.isFetching) {
										if (viewModel.pagination > 1 && viewModel.limit !== 200) {
											viewModel.setLimit(200)
											viewModel.setPagination(0)
											return
										}
										viewModel.setPagination(viewModel.pagination + 1)
									}
								}}
								isFetching={viewModel.isFetching}
							/>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default observer(TipsView)
