import { Col, Dropdown, Form, Row, Space, Tag, Typography } from "antd"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { FormField } from "../../../components/Form/FormField"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { User } from "../../../../domain/entities/User"
import MultiSwitch from "../../../components/Switch/MultiSwitch"
import { Language } from "../../../../locale/interfaces/Translation"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { CloseOutlined, DownOutlined } from "@ant-design/icons"
import MultimediaUploader from "../../../components/MultimediaUploader/MultimediaUploader"
import { DinamicSelectorWeightForm } from "../../sellersServices/components/DinamicSelectorWeightForm"
import { Microsite } from "../../../../domain/entities/Microsite"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { UsersViewModel } from "../UsersViewModel"
import { CustomBasicSelector as Selector } from "../../../components/CustomSelectors/CustomBasicSelector"

interface Props {
	inputDisabled?: boolean
	picture?: User["picture"]
	onChange?: (value: Partial<User> | any) => void
	userLanguage?: Language
	role?: string
	status?: string
	sealKey?: string
	originSite?: string
}

const UserInformationFormSection = ({
	inputDisabled,
	picture,
	onChange,
	userLanguage,
	role,
	sealKey,
	status,
	originSite
}: Props) => {
	const { t } = useTranslation("users", { keyPrefix: "form.userInfo" })
	const { t: tRoles } = useTranslation("users", { keyPrefix: "roles" })
	const [iconMenuOpened, setIconMenuOpened] = useState(false)
	const viewModel = useContainerInjection<UsersViewModel>("UsersViewModel")
	useEffect(() => {
		const isPartnerRole = User.roles.some(r => r.value === role && (r.key === "partner" || r.key === "seller"))
		if (
			isPartnerRole &&
			!viewModel.isLoadingPartnerData &&
			(viewModel.microsites.length === 0 || viewModel.reports.length === 0)
		) {
			viewModel.fetchPartnerData()
		}
	}, [role])

	return (
		<>
			<Row gutter={[24, 0]} align={"middle"} className="grid pb-4">
				<Col span={24} xl={12} md={12} xs={24} className="m-auto grid">
					<h4 className="mx-auto">{t("profilePicture")}</h4>
					<MultimediaUploader
						containerStyle={{ width: "160px", height: "160px", borderRadius: "50%", overflow: "hidden" }}
						mediaUrl={picture}
						onClean={() => onChange && onChange({ picture: undefined })}
						manualUpload
						label={t("uploadProfilePicture")}
						onChange={picture => onChange && onChange({ picture })}
					/>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"email"}
						label={t("email")}
						name={"email"}
						mandatory
						disabled={inputDisabled}
						onChange={(email: string) => onChange && onChange({ email })}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"password"}
						label={t("password")}
						name={"password"}
						mandatory
						disabled={inputDisabled}
						hideEditIcon={false}
						onChange={(password: string) => onChange && onChange({ password })}
					/>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"name"}
						label={t("name")}
						name={"name"}
						mandatory
						onChange={(name: string) => onChange && onChange({ name })}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.SelectSearch
						key={"status"}
						label={t("status")}
						name={"status"}
						mandatory
						placeholder={t("status").toString()}
						value={status}
						options={User.status.map(status => ({
							value: status.value,
							label: status.key,
							key: status.key
						}))}
						onChange={status =>
							//@ts-ignore
							onChange && onChange({ status: status.value })
						}
					/>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"userName"}
						label={t("userName")}
						name={"userName"}
						mandatory
						onChange={(userName: string) => onChange && onChange({ userName })}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.SelectSearch
						key={"role"}
						label={t("role")}
						name={"role"}
						mandatory
						placeholder={t("role").toString()}
						value={role}
						options={User.roles.map(role => ({
							value: role.value,
							label: tRoles(`${role.key}`),
							key: role.key
						}))}
						onChange={(role: any) => {
							onChange &&
								onChange({
									role: role.value,
									roleAttributes: undefined
								})
						}}
					/>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.DatePicker
						key={"birthdate"}
						mandatory
						label={t("birthdate")}
						name={"birthdate"}
						onChange={birthdate =>
							onChange && onChange({ birthdate: dayjs(birthdate).format(User.birthdateFormat) })
						}
					/>
				</Col>
				{User.roles.some(r => role === r.value && (r.key === "partner" || r.key === "seller")) && (
					<>
						<Col span={24} xl={12} md={12} xs={24} className="pt-3">
							<DinamicSelectorWeightForm
								className="mt-1"
								selectorStyle={{ width: "100%" }}
								label={t("userReportSelectorLabel")}
								mandatory
								name="reports"
								hideOrder
								onAdd={report =>
									onChange &&
									onChange({
										roleAttributes: {
											...viewModel.formData.roleAttributes,
											reports: (viewModel.formData.roleAttributes?.reports ?? []).concat([
												report.id.toString()
											])
										}
									})
								}
								data={(
									viewModel.reports.map(report => ({
										id: report.reportId,
										label: report.reportName,
										value: report.reportId
									})) ?? []
								).filter(r => !viewModel.formData.roleAttributes?.reports?.includes(r.id))}
								loading={viewModel.isLoadingPartnerData}
								disabled={viewModel.isLoadingPartnerData}
							/>
							{!viewModel.isLoadingPartnerData && (
								<Space className="pb-1" style={{ width: "100%", overflowX: "auto" }}>
									{viewModel.formData?.roleAttributes?.reports?.map(id => {
										const reportName = viewModel.reports.find(report => report.reportId === id)
											?.reportName
										return (
											<Tag
												key={id}
												closable
												style={{
													userSelect: "none",
													height: 30,
													display: "flex",
													alignItems: "center",
													borderRadius: 10
												}}
												onClose={() =>
													onChange &&
													onChange({
														roleAttributes: {
															...viewModel.formData.roleAttributes,
															reports: viewModel.formData.roleAttributes?.reports?.filter(
																reportId => reportId !== id
															)
														}
													})
												}
											>
												{reportName}
											</Tag>
										)
									})}
								</Space>
							)}
						</Col>
					</>
				)}
			</Row>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"city"}
						label={t("city")}
						name={"city"}
						onChange={(city: string) => onChange && onChange({ city })}
					/>
				</Col>

				{role === User.roles.find(r => r.key === "partner")?.value && (
					<Col span={24} xl={12} md={12} xs={24} className="pt-3">
						<DinamicSelectorWeightForm<Microsite>
							className="mt-1"
							selectorStyle={{ width: "100%" }}
							label={t("userMicrositeSelectorLabel").toString()}
							mandatory
							name="microsites"
							hideOrder
							onAdd={site =>
								onChange &&
								onChange({
									roleAttributes: {
										...viewModel.formData.roleAttributes,
										microsites:
											site.id !== "ES" && site.id !== "MX"
												? viewModel.formData.roleAttributes?.microsites?.concat([
														Number(site.id)
												  ]) ?? [site.id]
												: viewModel.formData.roleAttributes?.microsites,
										marketplaces:
											site.id === "ES" || site.id === "MX"
												? viewModel.formData.roleAttributes?.marketplaces?.concat(site.id) ?? [
														site.id
												  ]
												: viewModel.formData.roleAttributes?.marketplaces
									}
								})
							}
							data={[
								{
									id: "ES",
									label: `${t("marketplace")} ES`,
									value: "ES",
									keepLabel: true
								},
								{
									id: "MX",
									label: `${t("marketplace")} MX`,
									value: "MX",
									keepLabel: true
								}
							]
								.concat(
									viewModel.microsites.map(microsite => ({
										id: microsite.id!,
										label: microsite.slug,
										value: microsite.id!,
										keepLabel: false
									}))
								)
								.filter(
									m =>
										!viewModel.formData.roleAttributes?.microsites?.includes(Number(m.id)) &&
										!viewModel.formData.roleAttributes?.marketplaces?.includes(m.id!)
								)}
							loading={viewModel.isLoadingPartnerData}
							disabled={viewModel.isLoadingPartnerData}
						/>
						{!viewModel.isLoadingPartnerData && (
							<Space className="pb-1" style={{ width: "100%", overflowX: "auto" }}>
								{(viewModel.formData?.roleAttributes?.microsites?.map(id => id.toString()) ?? [])
									.concat(viewModel.formData.roleAttributes?.marketplaces ?? [])
									.map(id => {
										const siteName =
											viewModel.microsites.find(
												microsite => microsite.id?.toString() === id.toString()
											)?.slug ?? id
										return (
											<Tag
												key={id}
												closable
												style={{
													userSelect: "none",
													height: 30,
													display: "flex",
													alignItems: "center",
													borderRadius: 10
												}}
												onClose={() =>
													onChange &&
													onChange({
														roleAttributes: {
															...viewModel.formData.roleAttributes,
															microsites:
																viewModel.formData.roleAttributes?.microsites?.filter(
																	m => m.toString() !== id
																),
															marketplaces:
																viewModel.formData.roleAttributes?.marketplaces?.filter(
																	m => m.toString() !== id
																)
														}
													})
												}
											>
												{`${id === siteName ? t("marketplace") : id} - ${siteName}`}
											</Tag>
										)
									})}
							</Space>
						)}
					</Col>
				)}
			</Row>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"phoneNumber"}
						label={t("phone")}
						inputType={InputType.NUMBER}
						name={"phoneNumber"}
						onChange={(phoneNumber: string) => onChange && onChange({ phoneNumber })}
					/>
				</Col>
				<Col span={24} xl={3} md={12} xs={12}>
					<Form.Item className="pt-2">
						<Typography.Text className="mb-4">{t("originSite")}</Typography.Text>
						<Typography.Title style={{ fontSize: 16 }}>{originSite ?? "N/A"}</Typography.Title>
					</Form.Item>
				</Col>
				<Col span={24} xl={3} md={6} xs={12} className="pt-4">
					<MultiSwitch
						label={t("lang").toString()}
						options={[
							{ label: "ES", value: Language.esES },
							{ label: "EN", value: Language.enUS }
						]}
						value={userLanguage || Language.esES}
						onChange={language => onChange && onChange({ language })}
						buttonsStyles={{ width: 40 }}
						containerStyles={{ flexDirection: "column", alignItems: "flex-start", gap: 20 }}
					/>
				</Col>
				<Col span={24} xl={6} md={12} xs={12} className="pt-4">
					<Form.Item>
						<div className="flex items-center gap-4 mt-5">
							<p>{t("seal")}: </p>
							<Dropdown
								onOpenChange={setIconMenuOpened}
								trigger={["click"]}
								menu={{
									items: User.endorserSeals.map(seal => ({
										key: seal.key,
										label: (
											<div className="flex items-center gap-1">
												<div className="grid w-10 h-10 rounded-full m-2 p-2 shadow">
													<div
														className="m-auto flex"
														dangerouslySetInnerHTML={{ __html: seal.icon }}
													/>
												</div>
												<p>{t(`seals.${seal.key}`)}</p>
											</div>
										)
									})),
									selectable: true,
									onClick: item => {
										onChange && onChange({ endorserTypeKey: item.key })
										setIconMenuOpened(false)
									},
									className: "max-h-60 overflow-y-auto"
								}}
							>
								<div className="flex px-[.3rem] pt-[0.2rem] shadow rounded-full hover:cursor-pointer hover:opacity-80">
									{sealKey ? (
										<div className="grid relative">
											<div
												dangerouslySetInnerHTML={{
													__html:
														User.endorserSeals.find(seal => seal.key === sealKey)?.icon ||
														""
												}}
											/>
											<CloseOutlined
												className={`absolute -right-[.15rem] -top-[.15rem]  w-4 h-4 bg-gray-200 text-gray-500 p-1 rounded-full transition-all`}
												onClick={() => {
													onChange && onChange({ endorserTypeKey: undefined })
												}}
											/>
										</div>
									) : (
										<Space className="px-1">
											<p className="text-gray-400">{t("sealPlaceHolder")}</p>
											<DownOutlined
												className={`text-gray-400 w-4 h-4 transition-all ${
													iconMenuOpened ? "rotate-180" : ""
												}`}
											/>
										</Space>
									)}
								</div>
							</Dropdown>
						</div>
					</Form.Item>
				</Col>
			</Row>
			{/*
				<Row gutter={[24, 0]}>
					<Col xl={24} md={24} xs={24}>
						<FormField.Input
							key={"bio"}
							inputType={InputType.TEXTAREA}
							label={t("bio")}
							name={"bio"}
							onChange={(bio: string) => onChange && onChange({ bio })}
						/>
					</Col>
					<Col xl={24} md={24} xs={24}>
						<FormField.Input
							key={"goal"}
							inputType={InputType.TEXTAREA}
							label={t("goal")}
							name={"goal"}
							onChange={(goal: string) => onChange && onChange({ goal })}
						/>
					</Col>
				</Row>
				*/}
		</>
	)
}

export default observer(UserInformationFormSection)
