import { Layout, Button, Row, Col, Typography, Form, Spin } from "antd"
import signinbg from "../../assets/images/img-signin.jpg"
import logo from "../../assets/images/Imagotipo sporttips.png"
import { observer } from "mobx-react"
import { useState } from "react"
import { Login } from "../../../domain/entities/login"
import { SignInViewModel } from "./SignInViewModel"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { RoutePaths } from "../../navigation/RoutePaths.enum"
import { useToastMessage } from "../../service/toastMessage"
import Switch from "../../components/Switch/Switch"
import { FormField } from "../../components/Form/FormField"
import { InputType } from "../../components/TextInput/const/InputType.enum"
import { Roles } from "../../../domain/enum/roles.enum"
import { homePageRoute } from "../../navigation/routes"

const { Title } = Typography
const { Header, Content } = Layout

const initialFormData: Login = {
	email: "",
	password: ""
}

const SignInView = () => {
	const navigate = useNavigate()
	const viewModel = useContainerInjection<SignInViewModel>("SignInViewModel")
	const [formData, setFormData] = useState<Login>(initialFormData)
	const { t } = useTranslation("signInView")
	const { context, show } = useToastMessage()

	return (
		<>
			{context}
			<Layout className="layout-default layout-signin">
				<Header style={{ display: "flex", justifyContent: "space-between" }}>
					<div className="header-col header-brand">
						<img src={logo} alt="" />
					</div>
				</Header>
				<Content className="signin">
					<Row gutter={[24, 0]} justify="space-around">
						<Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 2 }} md={{ span: 12 }}>
							<Title className="mb-15 astoria-medium">{t("signIn")}</Title>
							<Title className="font-regular text-muted" level={5}>
								{t("subtitle")}
							</Title>
							<Form
								onFinish={async () => {
									try {
										if (formData.email && formData.password) {
											const user = await viewModel.login(formData)
											navigate(homePageRoute, { replace: true })
										}
									} catch (error) {
										show({
											type: "error",
											content: t("validations.wrongCredentials")
										})
									}
								}}
								initialValues={formData}
							>
								<FormField.Input
									key={"email"}
									label={t("email")}
									name={"email"}
									mandatory
									onChange={(value: string) => {
										setFormData({ ...formData, email: value })
									}}
								/>
								<FormField.Input
									key={"password"}
									label={t("password")}
									name={"password"}
									inputType={InputType.PASSWORD}
									mandatory
									onChange={(password: string) => {
										setFormData({ ...formData, password })
									}}
								/>
								<Row justify={"space-between"} className={"items-baseline"}>
									<Col>
										<Form.Item name="remember" className="aligin-center" valuePropName="checked">
											<Switch
												title={t("remember").toString()}
												checked={viewModel.rememberSession}
												defaultChecked
												onChange={checked => viewModel.setRememberSession(checked)}
												containerStyles={{ marginBottom: 0, justifyContent: "flex-start" }}
											/>
										</Form.Item>
									</Col>
									<Col>
										<a className={"text-right"} onClick={() => navigate(RoutePaths.FORGOT)}>
											{t("forgotPassword")}
										</a>
									</Col>
								</Row>

								<Form.Item>
									<Button
										htmlType="submit"
										disabled={viewModel.isLoading}
										className="w-full gap-2 flex items-center justify-center"
										loading={viewModel.isLoading}
									>
										{t("signIn")}
									</Button>
								</Form.Item>
							</Form>
						</Col>
						<Col
							className="sign-img"
							style={{ padding: 12 }}
							xs={{ span: 24 }}
							lg={{ span: 12 }}
							md={{ span: 12 }}
						>
							<img src={signinbg} alt="" />
						</Col>
					</Row>
				</Content>
			</Layout>
		</>
	)
}

export default observer(SignInView)
