import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import Main from "../components/Layout/Main"
import { homePageRoute, privateRoutes } from "./routes"
import ProtectedRoute from "./ProtectedRoute"
import { RoutePaths } from "./RoutePaths.enum"
import CategoriesView from "../pages/categories/CategoriesView"
import { useEffect } from "react"
import { useContainerInjection } from "../hooks/useContainerInjection"
import { AuthStore } from "../stores/AuthStore"
import { Roles } from "../../domain/enum/roles.enum"
import TransactionsView from "../pages/transactions/TransactionsView"

const PrivateRoutes = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const { userData } = useContainerInjection<AuthStore>("AuthStore")
	const hasAdminRole = userData?.roles?.includes(Roles.ADMIN)
	useEffect(() => {
		//@ts-ignore
		if (!Object.values(RoutePaths).includes(location.pathname)) {
			console.warn(`Route "${location.pathname}" not found.`)
			navigate(homePageRoute, { replace: true })
		}
	}, [location.pathname])
	const allowedRoutes = privateRoutes.filter(r =>
		r.roles.reduce((allowed, role) => allowed || (userData?.roles?.includes(role) ?? false), false)
	)
	return (
		<div>
			<Main>
				<Routes>
					{allowedRoutes.map(route => (
						<Route
							key={route.path}
							path={route.path}
							index={route?.index}
							element={
								<ProtectedRoute>
									<route.Component />
								</ProtectedRoute>
							}
						/>
					))}
					<Route path="*" element={hasAdminRole ? <CategoriesView /> : <TransactionsView />} />
				</Routes>
			</Main>
		</div>
	)
}

export default PrivateRoutes
