import { Menu } from "antd"
import { NavLink, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { privateRoutes } from "../../navigation/routes"
import SportTipsLogo from "../../assets/icons/SportTipsLogo.icon"
import { colors } from "../../assets/styles/appStyles"
import { useEffect, useState } from "react"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { ReactComponent as SubmenuIcon } from "../../assets/icons/hamburguer.svg"
import ActivityIndicator from "../ActivityIndicator/ActivityIndicator"

function Sidenav({ color, userData, isAuthenticated }) {
	const { t } = useTranslation("menu")
	const { pathname, state } = useLocation()
	const reportService = useContainerInjection("ReportService")
	const [page, setPage] = useState(pathname.replace("/", ""))
	const [loadingMenu, setLoadingMenu] = useState(false)
	const [subMenuItems, setSubMenuItems] = useState(
		privateRoutes.reduce(
			(items, route) => ({
				...items,
				[route.name]: route.children ? [] : undefined
			}),
			{}
		)
	)
	const [clickedRoute, setClickedRoute] = useState(page)
	useEffect(() => {
		if (isAuthenticated) loadMenu()
	}, [isAuthenticated])
	useEffect(() => {
		const current = pathname.replace("/", "")
		setPage(current)
		setClickedRoute(current)
	}, [pathname])
	const loadMenu = async () => {
		const items = {}
		setLoadingMenu(true)
		for (const route of privateRoutes) {
			items[route.name] = route.children ? await route.children({ reportService }) : []
		}
		setSubMenuItems(items)
		setLoadingMenu(false)
	}
	return (
		<>
			<div className="brand px-4">
				<SportTipsLogo color={colors.primary} />
			</div>
			<hr />
			<Menu theme="light" mode="inline">
				{privateRoutes
					.filter(r => r?.name)
					.filter(r => r.roles.reduce((allowed, role) => allowed || userData?.roles?.includes(role), false))
					.map((route, index) => {
						return (
							<Menu.Item key={index} onClick={() => setClickedRoute(route.name)}>
								{subMenuItems[route.name]?.length > 0 ? (
									<div style={{ padding: 15 }}>
										<span
											className="icon"
											style={{
												backgroundColor: page === route.name ? color : ""
											}}
										>
											{route.Icon ? <route.Icon /> : null}
										</span>
										<span className="label astoria-medium text-dark">{t(route.name)}</span>
										<div>
											{clickedRoute === route.name &&
												subMenuItems[route.name].map(item => (
													<Menu.Item key={item.id}>
														<NavLink
															to={route.path}
															state={{ menuItem: item }}
															style={{
																paddingTop: 12,
																paddingLeft: 20,
																backgroundColor: "transparent"
															}}
														>
															<span
																className="icon"
																style={{
																	minWidth: 25,
																	width: 25,
																	height: 25,
																	backgroundColor:
																		page === route.name &&
																		state?.menuItem?.id === item.id
																			? color
																			: "#eaeaea"
																}}
															>
																<SubmenuIcon style={{ width: 10 }} />
															</span>
															<span
																className="label astoria-medium"
																style={{
																	display: "inline-block",
																	whiteSpace: "normal"
																}}
															>
																{item.label}
															</span>
														</NavLink>
													</Menu.Item>
												))}
										</div>
									</div>
								) : (
									<>
										<NavLink to={route.path}>
											<span
												className="icon"
												style={{
													backgroundColor: page === route.name ? color : ""
												}}
											>
												{route.Icon ? <route.Icon /> : null}
											</span>
											<span className="label astoria-medium">{t(route.name)}</span>
										</NavLink>
										{clickedRoute === route.name && loadingMenu && subMenuItems[route.name] && (
											<ActivityIndicator
												styles={{ margin: 10 }}
												size="md"
												color={colors.activity}
											/>
										)}
									</>
								)}
							</Menu.Item>
						)
					})}
			</Menu>
		</>
	)
}

export default Sidenav
