import { ClusterError } from "../../ui/error/ClusterError"
import { CategoryIconModel } from "../models/CategoryIconModel"
import { CategoryModel } from "../models/CategoryModel"
import { Endpoint } from "../network/endpoint"
import { HttpManager } from "./../network/interface/HttpManager"

export class CategoryService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	public async getCategoriesList(): Promise<CategoryModel[]> {
		try {
			const response = await this._http.requestNewApi({
				method: "GET",
				url: Endpoint.CATEGORIES_LIST
			})
			return response.data.map((seller: any) => CategoryModel.fromRawJson(seller))
		} catch (error: any) {
			throw new ClusterError({
				errorMessage: ClusterError.CLUSTERS_FETCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async getCategoryIcons(): Promise<CategoryIconModel[]> {
		try {
			const response = await this._http.requestNewApi({
				method: "GET",
				url: Endpoint.CATEGORIES_ICONS
			})
			return response.data.map((icon: any) => CategoryIconModel.fromRawJson(icon))
		} catch (error: any) {
			throw new ClusterError({
				errorMessage: ClusterError.CLUSTER_ICONS_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async createCategory(category: CategoryModel): Promise<CategoryModel> {
		try {
			const { label, labelEn, labelCa, picture, position, parentId, countryId, iconId, highlighted } = category
			const data = {
				label,
				picture,
				labelEn,
				labelCa,
				position,
				parentId,
				countryId,
				iconId,
				highlighted
			}

			const response = await this._http.requestNewApi({
				method: "POST",
				url: Endpoint.CATEGORIES,
				data
			})
			return CategoryModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new ClusterError({
				errorMessage: ClusterError.CLUSTER_CREATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async updateCategory(category: CategoryModel): Promise<CategoryModel> {
		try {
			const data = {
				label: category.label,
				labelEn: category.labelEn,
				labelCa: category.labelCa,
				cluster_id: category.id,
				parentId: category.parentId,
				picture: category.picture,
				position: category.position,
				countryId: category.countryId,
				iconId: category.iconId,
				highlighted: category.highlighted,
				servicesAssociated: category.servicesAssociated?.map(s => ({
					id: s.id,
					weight: s.weight
				}))
			}

			if (!category.id) {
				throw ""
			}

			const response = await this._http.requestNewApi({
				method: "PATCH",
				url: Endpoint.CATEGORIES_UPDATE.replace(":categoryId", category.id.toString()),
				data
			})
			return CategoryModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new ClusterError({
				errorMessage: ClusterError.CLUSTER_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async deleteCategory(category: CategoryModel): Promise<CategoryModel> {
		try {
			if (!category.id) {
				throw ""
			}

			const response = await this._http.requestNewApi({
				method: "DELETE",
				url: Endpoint.CATEGORIES_DELETE.replace(":categoryId", category.id.toString())
			})
			return CategoryModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new ClusterError({
				errorMessage: ClusterError.CLUSTER_DELETE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async getCategoryDetail(categoryId: string): Promise<CategoryModel> {
		try {
			const response = await this._http.requestNewApi({
				url: `${Endpoint.CATEGORIES_DETAIL}/${categoryId}`,
				method: "GET"
			})
			return CategoryModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new ClusterError({
				errorMessage: ClusterError.CLUSTER_DETAIL_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}
}
