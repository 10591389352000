import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"

export default (isAdmin?: boolean) => {
	const { t } = useTranslation("services", { keyPrefix: "form.dataTableHeaders.services" })

	return isAdmin !== undefined ? columns(t).filter(c => isAdmin || !c.needsAdmin) : columns(t)
}

const columns = (t: TFunction) => [
	{
		title: "ID",
		dataIndex: "id",
		key: "id",
		sorteable: true
	},
	{
		title: t("weight"),
		dataIndex: "weight",
		key: "weight"
	},
	{
		key: "service",
		title: t("service"),
		dataIndex: "service",
		width: "32%"
	},
	{
		key: "user",
		title: t("user"),
		dataIndex: "user"
	},
	{
		key: "contact",
		title: t("contact"),
		dataIndex: "contact"
	},
	{
		key: "email",
		title: t("email"),
		dataIndex: "email"
	},
	{
		title: t("city"),
		dataIndex: "city",
		key: "city"
	},
	{
		title: t("status"),
		dataIndex: "status",
		key: "status",
		needsAdmin: true
	},
	{
		title: "",
		key: "editButton",
		dataIndex: "editButton"
	},
	{
		title: "",
		key: "duplicateButton",
		dataIndex: "duplicateButton"
	}
]
