import type { SellerServicesRepository } from "../../../repositories/sellerServicesRepository"
import { UseCase } from "../../useCase"
import { DeleteSellerServiceResponse } from "./response"
import { UpdateSellerServiceStatusRequest } from "./request"

export class UpdateSellerServiceStatusUseCase
	implements UseCase<UpdateSellerServiceStatusRequest, DeleteSellerServiceResponse>
{
	private _repository
	constructor({ SellerServicesRepository }: { SellerServicesRepository: SellerServicesRepository }) {
		this._repository = SellerServicesRepository
	}

	exec({ id, status }: UpdateSellerServiceStatusRequest) {
		return this._repository.updateSellerServiceStatus(id, status)
	}
}
