import { ServiceSessionModel } from "../models/ServiceSessionModel"
import { HttpManager } from "../network/interface/HttpManager"
import { Endpoint } from "../network/endpoint"
import { SessionError } from "../../ui/error/SessionError"

export class ServiceSessionService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	public async deleteServiceSession(session: ServiceSessionModel, serviceId: string): Promise<ServiceSessionModel> {
		try {
			const data = {
				...session,
				serviceSessionId: session.id,
				outprice: session.outprice || 0,
				price: session.price || 0,
				published: false
			}
			const response = await this._http.requestNewApi({
				method: "PATCH",
				url: Endpoint.SERVICE_SESSION_UPDATE,
				data
			})
			return ServiceSessionModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new SessionError({
				errorMessage: SessionError.SESSION_DELETE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async updateServiceSession(session: ServiceSessionModel, serviceId: string): Promise<ServiceSessionModel> {
		try {
			const data = {
				...session,
				serviceSessionId: session.id,
				outprice: session.outprice || 0,
				price: session.price || 0,
				published: true
			}
			const response = await this._http.requestNewApi({
				method: "PATCH",
				url: Endpoint.SERVICE_SESSION_UPDATE,
				data
			})
			return ServiceSessionModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new SessionError({
				errorMessage: SessionError.SESSION_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async createServiceSession(session: ServiceSessionModel, serviceId: string): Promise<ServiceSessionModel> {
		try {
			const data = {
				serviceId,
				title: session.title,
				multicurrency: session.multicurrency,
				price: session.price || 0,
				outprice: session.outprice || 0,
				description: session.description,
				subtitle: session.subtitle,
				serviceSessionTranslations: session.serviceSessionTranslations,
				published: true,
				position: session.position
			}
			const response = await this._http.requestNewApi({
				method: "POST",
				url: Endpoint.SERVICE_SESSION_CREATE,
				data
			})
			return ServiceSessionModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new SessionError({
				errorMessage: SessionError.SESSION_CREATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}
}
