import { makeObservable, observable, action, computed } from "mobx"
import { Roles } from "../../domain/enum/roles.enum"
import { jwtDecode } from "jwt-decode"
import { Jwt } from "../../domain/types/Jwt.type"

export class AuthStore {
	token: string = localStorage.getItem("token") || ""
	isAuthenticated: boolean = localStorage.getItem("token") ? true : false

	constructor() {
		makeObservable(this, {
			token: observable,
			userData: computed,
			isAuthenticated: observable,
			onLogOut: action
		})
	}

	get userData() {
		if (!this.isAuthenticated || !this.token) return null
		const { user } = jwtDecode<Jwt>(this.token)
		return user
	}

	get isAdmin() {
		return this.userData?.roles.includes(Roles.ADMIN) ?? false
	}

	public onLogin(token: string, rememberSession: boolean) {
		this.token = token
		rememberSession && localStorage.setItem("token", token)
		this.isAuthenticated = true
	}

	public onLogOut = () => {
		localStorage.removeItem("token")
		this.isAuthenticated = false
		window.location.reload()
	}
}
