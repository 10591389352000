import { UseCase } from "../../useCase"
import { TransactionsRepository } from "../../../repositories/TransactionsRepository"
import { UpdateTransactionRequest } from "./request"
import { UpdateTransactionResponse } from "./response"

export class UpdateTransactionUseCase implements UseCase<UpdateTransactionRequest, UpdateTransactionResponse> {
	private _repository
	constructor({ TransactionsRepository }: { TransactionsRepository: TransactionsRepository }) {
		this._repository = TransactionsRepository
	}

	async exec({ transaction, sendEmails }: UpdateTransactionRequest) {
		await this._repository.updateTransaction(transaction, sendEmails)
	}
}
