import { AuthRepository } from "../../../repositories/AuthRepository"
import { UseCase } from "../../useCase"
import { ForgotPasswordServiceRequest } from "./request"

export class ForgotPasswordUseCase implements UseCase<ForgotPasswordServiceRequest, any> {
	private _repository
	constructor({ AuthRepository }: { AuthRepository: AuthRepository }) {
		this._repository = AuthRepository
	}

	exec({ email }: ForgotPasswordServiceRequest) {
		return this._repository.forgotPassword(email)
	}
}
